<template>
    <div class="flowCOne">
        <div class="fcoBox">
            <!-- 标题 -->
            <div class="fcoTitle">如何配套这些平台的推广，提升每个平台的宣传效果？</div>
            <!-- 图片 -->
            <div class="fcoBgImg">
                <img src="https://iv.okvu.cn/vugw/img/img.png" alt="" class="focBg" />
                <dl>
                    <dt class="topImg1">
                        <img src="https://iv.okvu.cn/vugw/img/vu1111.png" alt="" class="ti1" />
                    </dt>
                    <dt class="bottomImg1">
                        <img src="https://iv.okvu.cn/vugw/img/vu1111.png" alt="" class="bi1" />
                    </dt>
                    <dt class="topImg2">
                        <img src="https://iv.okvu.cn/vugw/img/vu1111.png" alt="" class="ti2" />
                    </dt>
                    <dt class="bottomImg2">
                        <img src="https://iv.okvu.cn/vugw/img/vu1111.png" alt="" class="bi2" />
                    </dt>
                </dl>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "FlowCOne",
};
</script>

<style lang="less" scoped>
.flowCOne {
    width: 100%;
    height: 700px;
    background-color: rgb(97, 154, 201);
    .fcoBox {
        width: 1280px;
        height: 100%;
        margin: 0 auto;
        // 标题
        .fcoTitle {
            width: 70%;
            height: 50px;
            line-height: 50px;
            text-align: center;
            font-size: 30px;
            font-weight: 900;
            margin: 0 auto;
            padding-top: 87px;
            color: white;
        }
        // 图片
        .fcoBgImg {
            width: 100%;
            height: 600px;
            margin-top: 30px;
            text-align: center;
            position: relative;
            .focBg {
                padding-top: 120px;
            }
            .topImg1 {
                width: 250px;
                height: 70px;
                position: absolute;
                top: 30px;
                left: 100px;
                text-align: center;
                overflow: hidden;
                animation: infinite 3s 0.3s linear p-bottom;
                .ti1 {
                    position: absolute;
                    top: -602px;
                    right: -28px;
                }
            }
            .bottomImg1 {
                width: 70px;
                height: 70px;
                text-align: center;
                position: absolute;
                bottom: 80px;
                left: 182px;
                overflow: hidden;
                animation: infinite 3s 0.3s linear p-show;
                .bi1 {
                    position: absolute;
                    top: -416px;
                    right: -12px;
                }
            }
            .topImg2 {
                width: 250px;
                height: 70px;
                position: absolute;
                top: 30px;
                right: 100px;
                text-align: center;
                overflow: hidden;
                animation: infinite 3s 0.3s linear p-bottom;
                .ti2 {
                    position: absolute;
                    top: -602px;
                    right: -28px;
                }
            }
            .bottomImg2 {
                width: 250px;
                height: 70px;
                text-align: center;
                position: absolute;
                bottom: 80px;
                right: 100px;
                overflow: hidden;
                animation: infinite 3s 0.3s linear p-show;
                .bi2 {
                    position: absolute;
                    top: -503px;
                    right: -28px;
                }
            }
        }
        @keyframes p-bottom {
            0% {
                transform: translateY(0) scale(1);
                opacity: 1;
            }
            25% {
                transform: translateY(159px) scale(0.5);
            }
            50% {
                transform: translateY(318px) scale(0);
                opacity: 0;
            }
            99% {
                opacity: 0;
            }
            100% {
                transform: translateY(0) scale(1);
                opacity: 1;
            }
        }
        @keyframes p-show {
            0% {
                transform: translateY(-224px);
                opacity: 0;
            }
            60% {
                transform: translateY(0px);
                opacity: 0;
            }
            100% {
                opacity: 1;
            }
        }
    }
}
</style>

<template>
    <div class="flowCFour">
        <img src="http://iv.okvu.cn/vugw/img/bg1 (2).jpg" alt="" class="fcfBg" />
        <!-- 标题 -->
        <div class="fcfTitle wow animate__animated animate__fadeInDown">
            <p class="fcfBig">微信线下获客转提升</p>
            <p class="fcfSmall">好的二维码设置可以让范可儿自然顺畅的进入，差的二维码设置会让访客拒之门外</p>
        </div>
        <!-- 图文 -->
        <div class="fcfImgText">
            <!-- 左图 -->
            <div class="fcfLeft wow animate__animated animate__fadeInRight">
                <img src="https://iv.okvu.cn/vugw/img/img5-2.png" alt="" class="fcfImg" />
            </div>
            <!-- 中间列表 -->
            <div class="fcfList wow animate__animated animate__fadeInDown">
                <ul>
                    <li class="fcfLi">
                        <div class="fcfIcons">
                            <img class="fcfIcon" src="http://iv.okvu.cn/vugw/img/vu818.png" alt="" />
                        </div>
                        <p class="fcfP">文本</p>
                    </li>
                    <li class="fcfLi">
                        <div class="fcfIcons">
                            <img class="fcfIcon2" src="http://iv.okvu.cn/vugw/img/vu818.png" alt="" />
                        </div>
                        <p class="fcfP">图片</p>
                    </li>
                    <li class="fcfLi">
                        <div class="fcfIcons">
                            <img class="fcfIcon3" src="http://iv.okvu.cn/vugw/img/vu818.png" alt="" />
                        </div>
                        <p class="fcfP">视频</p>
                    </li>
                    <li class="fcfLi">
                        <div class="fcfIcons">
                            <img class="fcfIcon4" src="http://iv.okvu.cn/vugw/img/vu818.png" alt="" />
                        </div>
                        <p class="fcfP">表格</p>
                    </li>
                    <li class="fcfLi">
                        <div class="fcfIcons">
                            <img class="fcfIcon5" src="http://iv.okvu.cn/vugw/img/vu818.png" alt="" />
                        </div>
                        <p class="fcfP">文件</p>
                    </li>
                    <li class="fcfLi">
                        <div class="fcfIcons">
                            <img class="fcfIcon6" src="http://iv.okvu.cn/vugw/img/vu818.png" alt="" />
                        </div>
                        <p class="fcfP">音频</p>
                    </li>
                    <li class="fcfLi">
                        <div class="fcfIcons">
                            <img class="fcfIcon7" src="http://iv.okvu.cn/vugw/img/vu818.png" alt="" />
                        </div>
                        <p class="fcfP">联系方式</p>
                    </li>
                    <li class="fcfLi">
                        <div class="fcfIcons">
                            <img class="fcfIcon8" src="http://iv.okvu.cn/vugw/img/vu818.png" alt="" />
                        </div>
                        <p class="fcfP">页面跳转</p>
                    </li>
                </ul>
            </div>
            <!-- 右图 -->
            <div class="fcfRight wow animate__animated animate__fadeInLeft">
                <img src="https://iv.okvu.cn/vugw/img/img5-1.png" alt="" class="fcfImg" />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "FlowCFour",
    data() {
        return {};
    },
    mounted() {
        this.$AOS.init({
            offset: 200,
            duration: 600,
            easing: "ease-in-sine",
            delay: 100,
        });
        new this.$wow.WOW().init({
            boxClass: "wow",
            animateClass: "animate__animated",
            offset: 0,
            mobile: true,
            live: true,
        });
    },
};
</script>

<style lang="less" scoped>
.flowCFour {
    width: 100%;
    height: 800px;
    position: relative;
    overflow: hidden;
    .fcfBg {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        z-index: -1;
    }
    // 标题
    .fcfTitle {
        width: 50%;
        height: 75px;
        margin: 0 auto;
        margin-top: 105px;
        color: white;
        .fcfBig {
            width: 100%;
            height: 50px;
            line-height: 50px;
            text-align: center;
            font-size: 40px;
            font-weight: 900;
        }
        .fcfSmall {
            width: 100%;
            height: 25px;
            line-height: 25px;
            text-align: center;
        }
    }
    // 图文
    .fcfImgText {
        max-width: 1920px;
        height: 600px;
        margin: 0 auto;
        margin-top: 20px;
        // 左图
        .fcfLeft {
            width: 25%;
            height: 80%;
            float: left;
            overflow: hidden;
            position: relative;
            margin-top: 120px;

            .fcfImg {
                position: absolute;
                right: 1px;
                padding-top: 55px;
            }
        }
        // 中间列表
        .fcfList {
            width: 45%;
            height: 50%;
            margin-left: 2.5%;
            margin-top: 80px;
            float: left;
            ul {
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: space-evenly;
                flex-wrap: wrap;
                .fcfLi {
                    width: 23%;
                    height: 50%;
                    text-align: center;
                    .fcfIcons {
                        width: 83px;
                        height: 83px;
                        border-radius: 50%;
                        margin: 0 auto;
                        background-color: #fff;
                        position: relative;
                        overflow: hidden;
                        transition: 0.5s;
                        .fcfIcon {
                            width: 1815px;
                            height: 2750px;
                            position: absolute;
                            top: -155px;
                            right: -656px;
                        }
                        .fcfIcon2 {
                            position: absolute;
                            top: -140px;
                            right: -680px;
                        }
                        .fcfIcon3 {
                            position: absolute;
                            top: -140px;
                            right: -490px;
                        }
                        .fcfIcon4 {
                            width: 1600px;
                            height: 2600px;
                            position: absolute;
                            top: -142px;
                            right: -838px;
                        }
                        .fcfIcon5 {
                            width: 1763px;
                            height: 2660px;
                            position: absolute;
                            top: -144px;
                            right: -855px;
                        }
                        .fcfIcon6 {
                            width: 1700px;
                            height: 2650px;
                            position: absolute;
                            top: -145px;
                            right: -677px;
                        }
                        .fcfIcon7 {
                            width: 1600px;
                            height: 2600px;
                            position: absolute;
                            top: -142px;
                            right: -961px;
                        }
                        .fcfIcon8 {
                            width: 1650px;
                            height: 2600px;
                            position: absolute;
                            top: -142px;
                            right: -932px;
                        }
                    }
                    .fcfP {
                        width: 100%;
                        height: 20px;
                        line-height: 20px;
                        font-size: 25px;
                        font-weight: 900;
                        margin-top: 20px;
                        color: white;
                    }
                }
                .fcfLi:hover {
                    .fcfIcons {
                        transform: rotateY(180deg);
                    }
                }
            }
        }
        // 右图
        .fcfRight {
            width: 25%;
            height: 80%;
            float: right;

            overflow: hidden;
            margin-top: 120px;
            .fcfImg {
                padding-top: 55px;
            }
        }
    }
}
</style>

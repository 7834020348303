<template>
    <div class="flowCTwo">
        <div class="fctBox">
            <!-- 标题 -->
            <div class="fctTitle wow animate__animated animate__fadeInUp">SEM推广型网站提升获客转化</div>
            <!-- 图文介绍 -->
            <div class="fctImgTexts wow animate__animated animate__fadeInUp" data-wow-delay="0.2s">
                <!-- 左边区域 -->
                <div class="fctLeft">
                    <!-- 文字 -->
                    <div class="fctLText">
                        <ul>
                            <li class="fctLi">
                                <p class="fctLBig">帮助推广创意有高点击、高转化</p>
                                <p class="fctSmall">精心打造创作着陆页的展现效果</p>
                            </li>
                            <li class="fctLi">
                                <p class="fctLBig">优化网站-美观！美观！美观！</p>
                                <p class="fctSmall">SEM落地页既要个性化创意，又要保持布局和风格整体设计一致</p>
                            </li>
                            <li class="fctLi">
                                <p class="fctLBig">接待工具与网站系统对接</p>
                                <p class="fctSmall">接待工具与网站系统分离，与CRM分离，大大降低了沟通效果和获客转化率</p>
                            </li>
                            <li class="fctLi">
                                <p class="fctLBig">加注分析代码</p>
                                <p class="fctSmall">威有做搜索推广5年，精通搜索数据分析的理解，但一切分析基础都要有分析代码</p>
                            </li>
                        </ul>
                    </div>
                    <!-- 图片 -->
                    <div class="fctLImgs">
                        <img src="https://iv.okvu.cn/vugw/img/vu818comimg1.jpg" alt="" class="fctImg" />
                    </div>
                    <!-- 中间图片 -->
                    <div class="fctCenter">
                        <img src="https://iv.okvu.cn/vugw/img/5-box-2-bg.jpg" alt="" class="fcImg" />
                    </div>
                </div>
                <!-- 右边区域 -->
                <div class="fctRight">
                    <!-- 文字 -->
                    <div class="fctRText">
                        <p class="fctBig">SEM推广型网站，比一般网站建设难度高</p>
                        <p class="fctSmall">
                            SEM推广型网站，客户是直达栏目页甚至详细内容页，然后再访问其他页。
                            一般网站，访客是沿着首页-栏目页-内容页方式访问。
                        </p>
                        <p class="fctSmall">
                            落地页即要满足点击进来就喜欢，又要服从整体布局， 以免乱七八糟结构的网站有损企业形象反而降低转化。
                        </p>
                    </div>
                    <!-- 图片 -->
                    <div class="fctRImgs">
                        <div class="fctRTop">
                            <p class="fctRP">1.威有科技公司怎么样</p>
                            <img src="https://iv.okvu.cn/vugw/img/vu818comimg2.jpg" alt="" class="fctRimg" />
                        </div>
                        <div class="fctRBottom">
                            <p class="fctRP">2.集团网站开发</p>
                            <img src="https://iv.okvu.cn/vugw/img/vu818comimg3.jpg" alt="" class="fctRimg" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "FlowCTwo",
    mounted() {
        this.$AOS.init({
            offset: 200,
            duration: 600,
            easing: "ease-in-sine",
            delay: 100,
        });
        new this.$wow.WOW().init({
            boxClass: "wow",
            animateClass: "animate__animated",
            offset: 0,
            mobile: true,
            live: true,
        });
    },
};
</script>

<style lang="less" scoped>
.flowCTwo {
    width: 100%;
    height: 1200px;
    margin-top: 50px;
    .fctBox {
        width: 1280px;
        height: 100%;
        margin: 0 auto;
        overflow: hidden;
        // 标题
        .fctTitle {
            width: 50%;
            height: 50px;
            line-height: 50px;
            text-align: center;
            font-size: 36px;
            font-weight: 900;
            margin: 0 auto;
            margin-top: 20px;
        }
        // 图文介绍
        .fctImgTexts {
            width: 100%;
            height: 1055px;
            margin-top: 50px;
            position: relative;
            // 左区域
            .fctLeft {
                width: 45%;
                height: 100%;
                float: left;
                background-color: #f1e6da;
                // 文字
                .fctLText {
                    width: 85%;
                    height: 500px;
                    ul {
                        width: 100%;
                        height: 100%;
                        display: flex;
                        justify-content: space-evenly;
                        flex-direction: column;
                        .fctLi {
                            width: 100%;
                            height: 23%;
                            padding-left: 10px;
                            .fctLBig {
                                width: 100%;
                                height: 50px;
                                line-height: 50px;
                                font-size: 20px;
                                font-weight: 900;
                            }
                            .fctSmall {
                                width: 100%;
                                height: 30px;
                                line-height: 30px;
                                font-size: 15px;
                            }
                        }
                    }
                }
                // 图片
                .fctLImgs {
                    width: 85%;
                    height: 550px;
                    text-align: center;
                    margin-top: 30px;
                    .fctImg {
                        border-radius: 20px;
                    }
                }
            }
            // 中间图片
            .fctCenter {
                width: 240px;
                height: 100%;
                position: absolute;
                top: 0;
                left: 500px;
                .fcImg {
                    width: 100%;
                    height: 100%;
                }
            }
            // 右区域
            .fctRight {
                width: 45%;
                height: 100%;
                float: right;
                background-color: #eee;
                // 文字
                .fctRText {
                    width: 90;
                    height: 200px;
                    margin-left: 50px;
                    .fctBig {
                        width: 100%;
                        height: 50px;
                        line-height: 50px;
                        font-size: 20px;
                        font-weight: 900;
                    }
                    .fctSmall {
                        width: 100%;
                        line-height: 30px;
                    }
                }
                // 图片
                .fctRImgs {
                    width: 100%;
                    height: 700px;
                    margin-top: 50px;
                    .fctRTop,
                    .fctRBottom {
                        width: 100%;
                        height: 350px;
                        text-align: center;
                        .fctRP {
                            width: 100%;
                            height: 50px;
                            line-height: 50px;
                        }
                        .fctRimg {
                            margin-top: 50px;
                            border-radius: 20px;
                        }
                    }
                }
            }
        }
    }
}
</style>

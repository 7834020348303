<template>
    <!-- 流量转化 -->
    <div class="flowC">
        <!-- 内容一 -->
        <FlowCOne></FlowCOne>
        <!-- 内容二 -->
        <FlowCTwo></FlowCTwo>
        <!-- 内容三 -->
        <FlowCThree></FlowCThree>
        <!-- 内容四 -->
        <FlowCFour></FlowCFour>
    </div>
</template>

<script>
import FlowCOne from "./FlowCContent/FlowCOne.vue";
import FlowCTwo from "./FlowCContent/FlowCTwo.vue";
import FlowCThree from "./FlowCContent/FlowCThree.vue";
import FlowCFour from "./FlowCContent/FlowCFour.vue";

export default {
    name: "FlowC",
    components: {
        FlowCOne,
        FlowCTwo,
        FlowCThree,
        FlowCFour,
    },
};
</script>

<style lang="less" scoped>
.flowC {
    min-width: 1423px;
    margin: 0 auto;
    text-align: left;
}
</style>
